import { Account, AnnualOverview, Appointments, Orders, Subscription } from 'constants/pages';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRotateRight } from '@fortawesome/pro-light-svg-icons/faArrowRotateRight';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faBoxArchive } from '@fortawesome/pro-light-svg-icons/faBoxArchive';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck';
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear';
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons/faXmarkLarge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { Brand } from 'components/Brand';
import styles from './Menu.module.css';
import classnames from 'classnames';
import { SelectUser } from 'components/layouts/SelectUser';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useRouter } from 'next/router';

interface MenuItemProperties {
  label: string;
  href: string;
  icon: IconProp;
}

const MenuItem = ({ label, href, icon }: MenuItemProperties) => (
  <li>
    <Link href={href} className={styles.menuItem}>
      <FontAwesomeIcon icon={icon} />
      {label}
    </Link>
  </li>
);

interface MenuProperties {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  brandOnly?: boolean;
}

export const Menu = ({ isOpen, brandOnly, setIsOpen }: MenuProperties) => {
  const router = useRouter();

  useEffect(() => {
    setIsOpen(false);
  }, [setIsOpen, router]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Brand />
        {brandOnly ? undefined : (
          <button type="button" onClick={() => setIsOpen((previous) => !previous)} className={styles.icon}>
            <FontAwesomeIcon icon={isOpen ? faXmarkLarge : faBars} />
          </button>
        )}
      </div>

      <div
        className={classnames({
          [styles.menu]: true,
          [styles.hidden]: !isOpen,
        })}>
        {brandOnly ? undefined : (
          <div className={styles.menuItems}>
            <ul className={styles.menuItemsSection}>
              <MenuItem label="Bestellingen" href={Orders} icon={faBoxArchive} />
              <MenuItem label="Afspraken" href={Appointments} icon={faCalendarCheck} />
              <MenuItem label="Jaaroverzicht" href={AnnualOverview} icon={faListUl} />
              <MenuItem label="Abonnement" href={Subscription} icon={faArrowRotateRight} />
            </ul>
            <ul className={classnames(styles.menuItemsSection, styles.menuItemsSectionBottom)}>
              <SelectUser />
              <MenuItem label="Account" href={Account} icon={faGear} />
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
