import { useEffect, useState } from 'react';

const MOBILE_THRESHOLD = 768;

const debounce = (function_: (...arguments_: unknown[]) => unknown, ms = 200) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: unknown, ...arguments_: unknown[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => function_.apply(this, arguments_), ms);
  };
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const callback = debounce(() => {
      setIsMobile(window.innerWidth < MOBILE_THRESHOLD);
    });

    setIsMobile(window.innerWidth < MOBILE_THRESHOLD);

    addEventListener('resize', callback);
    return () => {
      removeEventListener('resize', callback);
    };
  }, []);

  return isMobile;
};
