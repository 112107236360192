import { DefaultLayout } from 'components/layouts/DefaultLayout';
import { NextPage } from 'next';

const Appointments: NextPage = () => (
  <DefaultLayout>
    <h1>Ik kan de pagina niet vinden...</h1>
    <p>Heb ik mijn lenzen wel in?</p>
  </DefaultLayout>
);

export default Appointments;
