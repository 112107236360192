import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons/faUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './SelectUser.module.css';
import { useUser } from 'providers/UserProvider';
import { CSSProperties, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useWindowDimensions } from 'hooks/use-window-dimensions';
import { useIsMobile } from 'hooks/use-is-mobile';
import classnames from 'classnames';
import { Loader } from 'components/Loader';

export const SelectUser = () => {
  const { selectedCustomer, customers, setSelectedCustomerCode } = useUser();
  const reference = useRef<HTMLLIElement>(null);
  const { height } = useWindowDimensions();
  const [listBottom, setListBottom] = useState(0);
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onMouseEnter = useCallback(() => {
    if (isMobile) {
      return;
    }
    open();
  }, [isMobile, open]);

  const onMouseLeave = useCallback(() => {
    if (isMobile) {
      return;
    }
    close();
  }, [close, isMobile]);

  useEffect(close, [close, isMobile]);

  // This is required because refs don't trigger an update when they are set.
  useEffect(() => {
    setListBottom(height - (reference.current?.getBoundingClientRect().bottom || 0));
  }, [height, selectedCustomer]);

  const listBottomStyle = useMemo<CSSProperties | undefined>(() => {
    if (isMobile) return;
    return {
      bottom: listBottom || 0,
    };
  }, [isMobile, listBottom]);

  const selectUser: MouseEventHandler<HTMLLIElement> = useCallback(
    (event) => {
      setIsOpen(false);
      const newUser = customers?.find((user) => user.code === event.currentTarget.dataset.code);
      if (newUser?.code) {
        setSelectedCustomerCode(newUser.code);
      }
    },
    [customers, setSelectedCustomerCode]
  );

  if (!selectedCustomer) {
    return (
      <li className={styles.menuItem}>
        <Loader />
      </li>
    );
  }

  if (customers && customers?.length <= 1) {
    return (
      <li className={styles.menuItem}>
        <FontAwesomeIcon icon={faUser} />
        {selectedCustomer?.firstName}
      </li>
    );
  }

  return (
    <li role="presentation" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={reference}>
      <button type="button" onClick={open} className={styles.menuItem}>
        <FontAwesomeIcon icon={faUser} />
        {selectedCustomer?.firstName || selectedCustomer?.name}
        <FontAwesomeIcon className={styles.chevron} icon={faChevronRight} />
      </button>
      <ul className={classnames(styles.users, { [styles.visible]: isOpen })} style={listBottomStyle}>
        {isMobile ? (
          <li role="presentation" onClick={close}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </li>
        ) : undefined}
        {customers?.map((user) => (
          <li
            role="presentation"
            data-code={user.code}
            onClick={selectUser}
            key={user.code}
            className={selectedCustomer?.code === user.code ? styles.selected : ''}>
            <FontAwesomeIcon icon={selectedCustomer?.code === user.code ? faUserSolid : faUser} />
            {user.firstName || user.name}
          </li>
        ))}
      </ul>
    </li>
  );
};
