import { useState } from 'react';
import styles from './DefaultLayout.module.css';
import { Menu } from 'components/layouts/Menu';
import classNames from 'classnames';

interface Properties {
  children: React.ReactNode;
  brandOnly?: boolean;
}

export const DefaultLayout = ({ children, brandOnly = false }: Properties) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.container}>
      <Menu brandOnly={brandOnly} isOpen={isOpen} setIsOpen={setIsOpen} />
      <main
        className={classNames(styles.main, {
          [styles.noScroll]: isOpen,
        })}>
        {children}
      </main>
    </div>
  );
};
